// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--0364a";
export var cardBg = "AngularTechStacksMobile-module--cardBg--d5a2b";
export var cards = "AngularTechStacksMobile-module--cards--5b5ee";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--4d7b6";
export var description = "AngularTechStacksMobile-module--description--6bbca";
export var heading = "AngularTechStacksMobile-module--heading--23c88";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--43a04";
export var nav = "AngularTechStacksMobile-module--nav--78a51";
export var navItem = "AngularTechStacksMobile-module--nav-item--c9029";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--2a388";
export var tabData = "AngularTechStacksMobile-module--tabData--4b096";
export var tech = "AngularTechStacksMobile-module--tech--9b54f";
export var techIcon = "AngularTechStacksMobile-module--techIcon--9b5b6";
export var techImg = "AngularTechStacksMobile-module--techImg--c081a";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--5451f";