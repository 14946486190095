// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "AngularTechStacks-module--TechStacksWebHireAngular--63c15";
export var cardBg = "AngularTechStacks-module--cardBg--6f151";
export var cards = "AngularTechStacks-module--cards--ae813";
export var cardsContentAngular = "AngularTechStacks-module--cardsContentAngular--05dbf";
export var description = "AngularTechStacks-module--description--e3a21";
export var heading = "AngularTechStacks-module--heading--741b4";
export var iconssCard = "AngularTechStacks-module--iconssCard--318dc";
export var nav = "AngularTechStacks-module--nav--008de";
export var navItem = "AngularTechStacks-module--nav-item--36354";
export var navbarBlock = "AngularTechStacks-module--navbarBlock--d0229";
export var tabData = "AngularTechStacks-module--tabData--21566";
export var tech = "AngularTechStacks-module--tech--6aad7";
export var techIcon = "AngularTechStacks-module--techIcon--4eda4";
export var techImg = "AngularTechStacks-module--techImg--68bb0";
export var technologyIcon = "AngularTechStacks-module--technologyIcon--2338c";